import img1 from "../imgs/1.jpg";
import React , {useEffect} from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Aos from 'aos';
import 'aos/dist/aos.css';
import a from "../imgs/offer3.jpg"
import b from "../imgs/nata.JPG"
import c from "../imgs/6.jpg"
// import a from "../imgs/new1.jpg"
// import b from "../imgs/new2.jpg"

const HomeBanner = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
       <Swiper
                 slidesPerView={'1'}
                 spaceBetween={0}
                 pagination={{
                     clickable: true,
                 }}
                className="mySwiper my"
            >
                <SwiperSlide>
                <img src={img1} alt="..." />
                <div className="slide-content">
    
    <div className="text-overlay text-home">
      <p>LAKESIDE HOTEL</p>
      <h1>
        <span>Zbuloni ekuilibrin e përsosur </span> <br className="br1" />
        të mikpritjes,luksit dhe rehatisë <br className="br1" />
      </h1>
      <a href="/">More</a>
    </div>
  </div>
                </SwiperSlide>

                <SwiperSlide>                    
                    <img
                    src={a}
                    alt="..." className="offer"/>
                               <div className="slide-content">
    
    <div className="text-overlay text-home">
      <p>LAKESIDE HOTEL</p>
      <h1>
        <span>Zbuloni ekuilibrin e përsosur </span> <br className="br1" />
        të mikpritjes,luksit dhe rehatisë <br className="br1" />
      </h1>
      <a href="/">More</a>
    </div>
  </div>
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src={b}
                        alt="..."
                    />
                                <div className="slide-content">
    
    <div className="text-overlay text-home">
      <p>LAKESIDE HOTEL</p>
      <h1>
        <span>Zbuloni ekuilibrin e përsosur </span> <br className="br1" />
        të mikpritjes,luksit dhe rehatisë <br className="br1" />
      </h1>
      <a href="/">More</a>
    </div>
  </div>
           
                </SwiperSlide>
                <SwiperSlide>                    
                    <img
                    src={c}
                    alt="..."
                />
        
                </SwiperSlide>
            </Swiper>
      {/* <div className="container-fluid banner-home">
        <div className="row wide-open display-home">
          <div
            className="col-lg-6 text-home"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <p>LAKESIDE HOTEL</p>
            <h1>
              <span>Zbuloni ekuilibrin e përsosur </span> <br className="br1" />
              të mikpritjes,luksit dhe rehatisë <br className="br1" />
            </h1>
            <a href="/">More</a>
          </div>
          <div
            className="col-lg-6 hover03 p-0"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <figure>
              <img src={img1} alt="" className="img-first" />
            </figure>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default HomeBanner;
