import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { motion, AnimatePresence } from "framer-motion";
import img2 from "../imgs/starLogo.png";
import mainDish from "../imgs/ofertaIftarit.jpg";
import dish1 from "../imgs/iftari1.jpg"; 
import dish2 from "../imgs/iftari2.jpg"; 
import dish3 from "../imgs/iftari4.jpg"; 
import dish4 from "../imgs/iftari5.jpg";

const SpecialMenu = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDish, setSelectedDish] = useState(null);
  
  useEffect(() => {
    Aos.init({
      once: true,
      mirror: false
    });
  }, []);
  
  const openModal = (dish, title, description) => {
    setSelectedDish({
      image: dish,
      title: title,
      description: description
    });
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };
  
  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflow = 'auto';
  };
  
  const dishes = [
    { img: dish1, title: "Menu 1", description: "Një pervoje e jashtezakonshme per muajin e shenjte!" },
    { img: dish2, title: "Menu 2", description: "Një pervoje e jashtezakonshme per muajin e shenjte!" },
    { img: dish3, title: "Menu 3", description: "Një pervoje e jashtezakonshme per muajin e shenjte!" },
    { img: dish4, title: "Menu 4", description: "Një pervoje e jashtezakonshme per muajin e shenjte!" }
  ];

  return (
    <div className="container-fluid special-menu-section">
      {/* Header Section */}
      <div className="row mb-5">
        <div className="col-lg-8 mx-auto text-center" data-aos="fade-up" data-aos-duration="1500">
          <img src={img2} alt="" className="star-icons" />
          <h1 className="special-menu-title">
            Menuja jonë speciale për <span>Iftar</span>
          </h1>
          <p className="menu-subtitle">
            Përjetoni shijen e vërtetë të kuzhinës sonë të përgatitur me kujdes për Iftar.
            Chefat tanë kanë krijuar një menu të veçantë që kombinon traditat me shije moderne.
          </p>
        </div>
      </div>
      
      {/* Enhanced Main Image - New Design */}
      <div className="row mb-5">
        <div className="col-lg-10 mx-auto" data-aos="fade-up" data-aos-duration="1500">
          <div className="iftar-showcase">
            <div className="iftar-showcase-image">
              <img 
                src={mainDish} 
                alt="Iftar Special" 
                className="img-fluid" 
              />
            </div>
            <div className="iftar-showcase-content">
              <div className="iftar-showcase-badge">
                <span>Special</span>
              </div>
              <h2>Iftar Special Menu</h2>
              <div className="iftar-showcase-decoration"></div>
              <p>Një përvojë kulinare e veçantë për muajin e shenjtë</p>
              <button className="iftar-showcase-button" onClick={() => openModal(mainDish, "Iftar Special Menu", "Një përvojë kulinare e veçantë për muajin e shenjtë")}>
                Shiko Detajet
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {/* Carousel Section */}
      <div className="row">
        <div className="col-12" data-aos="fade-up" data-aos-duration="1500">
          <div className="menu-carousel-container">
            <Carousel 
              showArrows={true}
              showStatus={false}
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={3000}
              centerMode={true}
              centerSlidePercentage={33.33}
              emulateTouch={true}
              swipeable={true}
              className="menu-carousel"
              showIndicators={false}
              stopOnHover={false}
            >
              {dishes.map((dish, index) => (
                <div 
                  className="dish-card" 
                  key={index}
                  onClick={() => openModal(dish.img, dish.title, dish.description)}
                >
                  <div className="dish-badge">Special Menu</div>
                  <div className="dish-image-container">
                    <img src={dish.img} alt={`Special Dish ${index + 1}`} />
                    <div className="dish-overlay">
                      <span>View Details</span>
                    </div>
                  </div>
                  <div className="dish-info">
                    <h3>{dish.title}</h3>
                    <p>{dish.description}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      
      {/* Minimalistic CTA Section */}
      <div className="row mt-5">
        <div className="col-lg-8 mx-auto" data-aos="fade-up" data-aos-duration="1000">
          <div className="menu-cta-banner">
            <div className="menu-cta-content">
              <h3>Rezervoni Tavolinën Tuaj</h3>
              <div className="menu-cta-buttons">
                <a href="tel:+38348808408" className="menu-cta-phone">
                  <i className="fas fa-phone-alt"></i>
                  <span>+383 48 808 408</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Improved Modal with Better Image Display */}
      <AnimatePresence>
        {modalOpen && selectedDish && (
          <motion.div 
            className="dish-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeModal}
          >
            <motion.div 
              className="dish-modal-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ type: "spring", damping: 25 }}
              onClick={(e) => e.stopPropagation()}
            >
              <button className="dish-modal-close" onClick={closeModal}>
                <i className="fas fa-times"></i>
              </button>
              
              <div className="dish-modal-container">
                <div className="dish-modal-image-container">
                  <motion.img 
                    src={selectedDish.image} 
                    alt={selectedDish.title}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="dish-modal-image"
                  />
                </div>
                
                <motion.div 
                  className="dish-modal-info"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  <h2>{selectedDish.title}</h2>
                  <div className="dish-modal-decoration"></div>
                  <p>{selectedDish.description}</p>
                </motion.div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SpecialMenu;