// Booking.jsx
import React, { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Modal from "react-modal";


const Booking = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const openReservationModal = () => {
    setIsReservationModalOpen(true);
  };

  const closeReservationModal = () => {
    setIsReservationModalOpen(false);
  };

  const openThankYouModal = () => {
    setIsThankYouModalOpen(true);
  };

  const closeThankYouModal = () => {
    setIsThankYouModalOpen(false);
  };

  const handleReserve = () => {
    // Close the reservation modal
    closeReservationModal();

    // Open the thank-you modal
    openThankYouModal();
  };

  const handleSubmit = async () => {
    try {
      // Send reservation details to the server (PHP) via API

      // const response = await fetch("https://lakesideks.com/reservation.php", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/x-www-form-urlencoded",
      //   },
      //   body: new URLSearchParams({
      //     checkIn,
      //     checkOut,
      //     adults,
      //     children,
      //     name,
      //     surname,
      //     phone,
      //     email,
      //   }),
      // });

      // if (!response.ok) {
      //   throw new Error(`HTTP error! Status: ${response.status}`);
      // }

      // Optionally, handle the success response
      console.log("Reservation submitted successfully!");

      // Open the thank-you modal after successful submission
      openThankYouModal();
    } catch (error) {
      console.error("Error submitting reservation:", error);
    }
  };

  return (
    <div className="booking-home">
      <section className="book">
        <div className="container-fluid flex" id="remove-flex">
          <div className="input grid row m-auto">
          <div className="box" data-aos="fade-up" data-aos-duration="1000">
              <label>Check-in:</label>
              <input
                type="date"
                placeholder="Check-in-Date"
                className="inp-home"
                value={checkIn}
                onChange={(e) => setCheckIn(e.target.value)}
              />
            </div>
            <div className="box" data-aos="fade-down" data-aos-duration="2000">
              <label>Check-out:</label>
              <input
                type="date"
                placeholder="Check-out-Date"
                className="inp-home"
                value={checkOut}
                onChange={(e) => setCheckOut(e.target.value)}
              />
            </div>
            <div className="box" data-aos="fade-up" data-aos-duration="3000">
              <label>Adults:</label> <br />
              <input
                type="number"
                placeholder="0"
                className="inp-home"
                value={adults}
                onChange={(e) => setAdults(e.target.value)}
              />
            </div>
            <div className="box" data-aos="fade-down" data-aos-duration="1000">
              <label>Children:</label> <br />
              <input
                type="number"
                placeholder="0"
                className="inp-home"
                value={children}
                onChange={(e) => setChildren(e.target.value)}
              />
            </div>
            <div
              className="box box1"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <input
                type="button"
                value="Rezervo dhomën"
                className="btn-book-home"
                onClick={openReservationModal}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Reservation Form Modal */}
      <Modal
        isOpen={isReservationModalOpen}
        onRequestClose={closeReservationModal}
        contentLabel="Reservation Form Modal"
        overlayClassName="reservation-overlay"
        ariaHideApp={false}
        className="modali"
      >
        <div className="reservation-content">
          <h2>Reservation Details</h2>
          <p><b>Check-in:</b> {checkIn}</p>
          <p><b>Check-out:</b> {checkOut}</p>
          <p><b>Adults:</b> {adults}</p>
          <p><b>Children:</b> {children}</p>

          {/* Additional form fields for name, surname, phone number */}
          <div className="form-group nisi">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="surname">Surname:</label>
            <input
              type="text"
              id="surname"
              name="surname"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number:</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="butonat">
            {/* Button to reserve */}
            <button onClick={() => { handleSubmit(); handleReserve(); }} className="w">
              Reserve
            </button>

            {/* Close button */}
            <button onClick={closeReservationModal} className="wi">
              Close
            </button>
          </div>
        </div>
      </Modal>

      {/* Thank You Modal */}
      <Modal
        isOpen={isThankYouModalOpen}
        onRequestClose={closeThankYouModal}
        contentLabel="Thank You Modal"
        ariaHideApp={false}
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <div className="modal-content">
          <h1>Thank you!</h1>
          <p>Per rezervime kontaktoni në numrin e telefonit +383 48 808 408.</p>
          <p>Ne do te ju kontaktojme shume shpejte.</p>
          <button onClick={closeThankYouModal}>Close</button>
        </div>
      </Modal>
    </div>
  );
};

export default Booking;
