import React, { useRef, useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Navbar from '../components/NavbarCompnoonent'
import Footer from '../components/Footer'
import img1 from "../imgs/icons8-users-32.png";
import img2 from "../imgs/icons8-wifi-50.png";
import img3 from "../imgs/icons8-bed-80.png";
import img4 from "../imgs/icons8-breakfast-64.png";
import img5 from "../imgs/icons8-square-border-24.png";
import img6 from "../imgs/icons8-pool-50.png";
import startsicon from "../imgs/starLogo.png"
import checks from "../imgs/icons8-instagram-check-mark-50.png"
import MapGoogle from '../components/MapGoogle';
import ToTopButton from '../components/ToTopButton';
const Details = () => {
    return (
        <>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img
                        src="https://duruthemes.com/demo/html/cappa/demo1-light/img/slider/4.jpg"
                        alt="..."
                    />
                </SwiperSlide>
                <SwiperSlide>                    
                    <img
                    src="https://duruthemes.com/demo/html/cappa/demo1-light/img/slider/3.jpg"
                    alt="..."
                /></SwiperSlide>
                <SwiperSlide>
                    <img
                        src="https://duruthemes.com/demo/html/cappa/demo1-light/img/slider/2.jpg"
                        alt="..."
                    />
                </SwiperSlide>
                <SwiperSlide>                    
                    <img
                    src="https://duruthemes.com/demo/html/cappa/demo1-light/img/slider/1.jpg"
                    alt="..."
                /></SwiperSlide>
            </Swiper>
            <div className='container details-full'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-lg-7 left-detail-part' data-aos="fade-down" data-aos-duration="1000">
                        <img src={startsicon} />
                        <h6>LAKESIDE HOTEL</h6>
                        <h1>Standard Room</h1>
                        <br />
                        <p>Hotel non lorem ac erat suscipit bibendum nulla facilisi. Sedeuter nunc volutpat miss sapien vel conseyen
                            turpeutionyer masin libero sevenion vusetion viventa augue sit amet hendrerit vestibulum. Duisteyerion
                            venenatis lacus gravida eros ut turpis interdum ornare.</p>
                        <br />

                        <p>Hotel non lorem ac erat suscipit bibendum nulla facilisi. Sedeuter nunc volutpat miss sapien vel conseyen
                            turpeutionyer masin libero sevenion vusetion viventa augue sit amet hendrerit vestibulum. Duisteyerion
                            venenatis lacus gravida eros ut turpis interdum ornare.</p>
                        <div className='row checking-div'>
                            <div className='col-lg-6'>
                                <h2>Check-in</h2>
                                <div className='checks'>
                                    <img src={checks} />
                                    <h4>Check-in from 9:00 AM - anytime</h4>
                                </div>
                                <div className='checks'>
                                    <img src={checks} />
                                    <h4>Check-in from 9:00 AM - anytime</h4>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <h2>Check-in</h2>
                                <div className='checks'>
                                    <img src={checks} />
                                    <h4>Check-in from 9:00 AM - anytime</h4>
                                </div>
                                <div className='checks'>
                                    <img src={checks} />
                                    <h4>Check-in from 9:00 AM - anytime</h4>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <h2>Special check-in instructions</h2>
                        <p>Guests will receive an email 5 days before arrival with check-in instructions; front desk staff will greet guests on arrival For more details, please contact the property using the information on the booking confirmation.</p>
                    </div>
                    <div className='col-lg-5 amenities-div' data-aos="fade-up" data-aos-duration="1000">
                        <h1
                            className='cl-anime'>Amenities</h1>
                        <div className='div-of-icons-details'>
                            <ul>
                                <li>
                                    <img src={img1}></img>
                                    <p>1-2 Persons</p>
                                </li>
                                <li>
                                    <img src={img2}></img>
                                    <p>Free Wifi</p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={img3}></img>
                                    <p>Twin Bed</p>
                                </li>
                                <li>
                                    <img src={img4}></img>
                                    <p>Breakfast</p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src={img5}></img>
                                    <p>200 sqft room</p>
                                </li>
                                <li>
                                    <img src={img6}></img>
                                    <p>Swimming Pool</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br/>
            <MapGoogle/>
            <ToTopButton/>
            <Footer />
        </>
    )
}

export default Details