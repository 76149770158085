import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import offerImage from "../imgs/oferta1.jpg";
import img2 from "../imgs/starLogo.png";

const OfferSection = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="container-fluid about-part mt-5 mb-5">
      <div className="row">
        <div
          className="col-lg-6 about-left-part-home"
          data-aos="fade-down"
          data-aos-duration="2000"
        >
          <img src={img2} alt="" className="star-icons" />
          <p>LAKESIDE HOTEL</p>
          <h1 className="title-about-us">
            Për rezervime <br /> <span>na kontaktoni!</span>
          </h1>
          <br />
          <p>
            Rezervoni qëndrimin tuaj në Lakeside Hotel dhe përjetoni luksin dhe komoditetin që ofrojmë.
            Për çdo informacion shtesë ose për të bërë një rezervim, na kontaktoni drejtpërdrejt.
          </p>
          <p>
            Stafi ynë i përkushtuar është në dispozicionin tuaj për t'ju ndihmuar me çdo kërkesë.
          </p>
        </div>
        <div className="col-lg-6">
          <div className="offer-image-container">
            <img
              src={offerImage}
              alt="Special Offer"
              className="offer-image-custom"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferSection;